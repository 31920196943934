<template>
  <EOWItem class="p-mb-3" v-for="(data, i) in source" :key="i" :item="data" :loading="loading" />
</template>

<script setup>
import { computed } from 'vue'

import EOWItem from './EOWDetailItem.vue'

const props = defineProps({
  eows: { type: Object, required: false },
  loading: { type: Boolean, default: true }
})

const loadingData = [
  {
    eow_name: 'Loading',
    fields: [{ label: 'Loading' }, { label: 'Loading' }, { label: 'Loading' }]
  }
]
const source = computed(() => (props.loading ? loadingData : props.eows))
</script>
