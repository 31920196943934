<template>
  <Divider align="left">
    <b>{{ title || 'Loading' }}</b>
  </Divider>
  <Skeleton v-if="loading" class="p-mt-3" :height="loadingHeight" />
  <slot v-else name="value">
    <p class="field-content" v-if="type === 'html'" v-html="cleanValue"></p>
    <p class="field-content" v-else>{{ cleanValue }}</p>
  </slot>
</template>

<script setup>
import { computed } from 'vue'
import { FIELD_TYPES } from '../../../definitions'

const props = defineProps({
  title: { type: String, required: true },
  value: { type: [String, Number], required: false },
  type: { type: String, default: 'text' },
  fieldType: { type: String, required: false },
  loading: { type: Boolean, default: false },
  loadingHeight: { type: String, default: '8rem' }
})

const cleanValue = computed(() => {
  if (props.fieldType === FIELD_TYPES.CALENDAR.code && props.value?.includes('T')) {
    return props.value.split('T')[0]
  }
  return props.value
})
</script>

<style scoped>
.field-content {
  background-color: var(--surface-b);
  border-radius: 0.2rem 0.2rem 0 0;
  padding: 0.8rem;
}
</style>
