import { ref, watch } from 'vue'
import { useRouter } from 'vue-router'

import useReport from './useReport'

export default reportId => {
  const router = useRouter()
  const { error, getReport, loading } = useReport()

  const report = ref(null)

  watch(error, value => {
    if (value) {
      if (value.response?.status === 403) {
        router.replace({ name: 'access-denied' })
      } else if (value.response?.status === 404) {
        router.replace({ name: 'not-found' })
      }
    }
  })

  // created
  ;(async () => {
    const resp = await getReport(reportId)
    if (!error.value && resp.data) {
      report.value = resp.data
    }
  })()

  return { loading, report }
}
