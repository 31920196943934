<template>
  <h4></h4>
  <div class="p-grid" style="max-width: 1200px; min-width: 455px">
    <div class="p-col-12 p-md-12 p-lg-8">
      <EOWDetailList :eows="report?.data?.forms" :loading="loading" />
    </div>
    <div class="p-col-12 p-md-12 p-lg-4">
      <ReportMeta :loading="loading" :report="report" />
    </div>
  </div>
</template>

<script setup>
import EOWDetailList from '../../components/reporting/detail/EOWDetailList.vue'
import useReportDetail from '../../hooks/useReportDetail'
import ReportMeta from '../../components/reporting/detail/ReportMeta.vue'

const props = defineProps({
  reportId: { type: [Number, String], required: true }
})

const { loading, report } = useReportDetail(props.reportId)
</script>
